<style scoped>
	.border-icon {
		border: 1px rgb(172, 172, 172) solid;
		border-radius: 4px;
	}
	.switch {
		position: relative;
		display: inline-block;
		width: 53px;
		height: 25px;
		margin-bottom: 0;
	}

	.switch input {
		display: none;
	}

	.slider {
		position: absolute;
		cursor: pointer;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: #ccc;
		-webkit-transition: 0.4s;
		transition: 0.4s;
	}

	.slider:before {
		position: absolute;
		content: "";
		height: 20px;
		width: 20px;
		left: 4px;
		bottom: 3px;
		background-color: white;
		-webkit-transition: 0.4s;
		transition: 0.4s;
	}

	input:checked + .slider {
		background-color: #51C1AD;
	}

	input:focus + .slider {
		box-shadow: 0 0 1px #51C1AD;
	}

	input:checked + .slider:before {
		-webkit-transform: translateX(26px);
		-ms-transform: translateX(26px);
		transform: translateX(26px);
	}

	.slider.round {
		border-radius: 34px;
	}

	.slider.round:before {
		border-radius: 50%;
	}
</style>
<template>
	<Layout>
		<!-- <PageHeader :title="title" :items="items" /> -->
		<div class="row">
			<div class="col-12">
				<form @submit.prevent="submitData">
					<ul class="nav nav-tabs pt-3" style="border: 0;">
						<li class="active"><a href="#" class="btn btn-sm btn-head text-white">General Information</a></li>
					</ul>
					<div class="card border">
						<div class="card-body">
							<div class="row mt-3">
								<div class="col-12">
									<div class="form-row">
										<div class="col-md-3">
											<div class="form-group">
												<label for="">Date Quotation</label>
												<!-- <input v-model="dataQuotation.date_input" type="date" class="form-control" required> -->
												<date-picker v-model="dataQuotation.date_input" :format="momentFormat" type="date" lang="en"
													:placeholder="'Select Date'" @input="getTgl">
												</date-picker>
											</div>
										</div>
										<div class="col-md-2">
											<div class="form-group">
												<label for="">Customer</label>
												<!-- <select v-model="dataQuotation.customer_id" data-live-search="true" class="form-control" required>
													<option v-for="(isi, key) in listCustomerQuo" :key="key" :value="isi.id">
														{{isi.name}}
													</option>
												</select> -->
												<div v-if="!isHidden">
													<v-select v-model="nameCustomer" :options="listCustomerQuo" label="name"
														@input="changeCustomer">
													</v-select>
												</div>
												<div v-if="isHidden">
													<input v-model="dataQuotation.customer_manual" type="text" class="form-control form-control-sm"
														placeholder="Customer" />
												</div>
												<!-- <multiselect id="customer" v-model="nameCustomer" placeholder="Choose a Customer" :searchable="true" :close-on-select="true" :options="listCustomerQuo" label="name" required @input="changeCustomer"></multiselect> -->
											</div>
										</div>
										<div style="margin-top: 30px">
											<div class="form-group">
												<a href="javascript:void(0)" class="btn btn-sm btn-create" @click="manualInput(isHidden)">
													<i class="fa fa-pencil-alt fa-lg"></i>
												</a>
											</div>

										</div>
										<div class="col-md-3">
											<div class="form-group">
												<label for="">Subject</label>
												<input v-model="dataQuotation.subject" type="text" class="form-control" placeholder="Subject"
													required />
											</div>
										</div>
										<div class="col-md-3">
											<div class="form-group">
												<label for="">Attn</label>
												<input v-model="dataQuotation.pic_name" type="text" class="form-control" placeholder="Attn"
													required />
											</div>
										</div>
										<div class="col-md-3">
											<div class="form-group">
												<label for="">Poft of Delivery</label>
												<!-- <select v-model="dataQuotation.pod" data-live-search="true" class="form-control" required>
													<option v-for="(isi, key) in listPort" :key="key" :value="isi.id">
														{{isi.name_port}}
													</option>
												</select> -->
												<v-select v-model="pod" :options="listPort" label="name_port" @input="changePod">
												</v-select>
												<!-- <multiselect id="pod" v-model="dataQuotation.podname" placeholder="Port of Delivery" :searchable="true" :allow-empty="true" :clearable="true" :loading="isLoading" :close-on-select="true" :options="listPort" label="name_port" required @input="changePod"></multiselect> -->
											</div>
										</div>
										<div class="col-md-3">
											<div class="form-group">
												<label for="">Port of Loading</label>
												<!-- <select v-model="dataQuotation.pol" data-live-search="true" class="form-control" required>
													<option v-for="(isi, key) in listPort" :key="key" :value="isi.id">
														{{isi.name_port}}
													</option>
												</select> -->
												<v-select v-model="pol" :options="listPort" label="name_port" @input="changePol">
												</v-select>
												<!-- <multiselect id="pol" v-model="dataQuotation.polname" placeholder="Port of Loading" :searchable="true" :allow-empty="true" :clearable="true" :loading="isLoading" :close-on-select="true" :options="listPort" label="name_port" required @input="changePol">
												</multiselect> -->
											</div>
										</div>
										<div class="col-md-3">
											<div class="form-group">
												<label for="">Term</label>
												<!-- <select v-model="dataQuotation.term_id" data-live-search="true" class="form-control" required>
													<option v-for="(isi, key) in listTerm" :key="key" :value="isi.id">
														{{isi.name}}
													</option>
												</select> -->
												<v-select v-model="idTerm" :options="listTerm" label="name" @input="changeTerm">
												</v-select>
												<!-- <multiselect id="term_quotation" v-model="dataQuotation.term" placeholder="Term Quotation" :searchable="true" :allow-empty="true" :clearable="true" :loading="isLoading" :close-on-select="true" :options="listTerm" label="name" required @input="changeTerm">
												</multiselect> -->
											</div>
										</div>


										<!-- <div class="col-md-3"> -->
										<!-- <label for="">PPN</label>
											<select v-model="dataQuotation.ppn" class="form-control" placeholder="Choose PPn" required @input="changePpn">
												<option v-for="(item,key) in listPpn" :key="key" :value="item.ppn">
													{{item.name}}</option>
											</select> -->

										<!-- <v-select v-model="ppn" :options="listPpn" label="name" @input="changePpn">
												</v-select> -->
										<!-- <multiselect v-model="dataQuotation.ppnShow" placeholder="Choose PPn" :options="listPpn" label="name" @input="changePpn">
											</multiselect> -->
										<!-- </div> -->
										<!--										<div v-show="dataQuotation.currency[0].default === 0" class="col-md-3">-->
										<!-- <div v-show="dataQuotation.currency_id !== defaultCurrency" class="col-md-3">
											<label for="">Commercial Rate</label>
											<input v-model="dataQuotation.rate" type="number" class="form-control" placeholder="Commercial Rate">
										</div> -->
										<div class="col-md-3">
											<label for=""></label>
											<div class="form-check mt-2">
												<input id="includeTax" v-model="includeTax" type="checkbox" value="1"
													:checked="dataQuotation.include_tax === 1" class="form-check-input"
													style="width: 15px; height: 15px;" @change="cekIncludeTax">
												<label for="includeTax">Include Tax</label>
											</div>
										</div>

									</div>
								</div>
							</div>
						</div>
					</div>

					<ul class="nav nav-tabs " style="border: 0;">
						<li class="active"><a href="#" class="btn btn-sm btn-head text-white">Input Type Quatation</a></li>
					</ul>
					<div class="card border">
						<div class="card-body">
							<div class="row mt-3">
								<div class="col-12">
									<div class="form-row">
										<!-- form type quotation -->
										<div v-for="(item, k) in types" :key="k" class="col-md-12 mt-2">
											<div class="card border">
												<div class="card-header">
													<div class="row">

														<div class="col-md-6">
															<div class="row">
																<div class="col-md-3">
																	<label for="">Type Quotation <span class="text-danger">*</span></label>
																</div>
																<div class="col-md-6">
																	<input v-model="item.type" type="text" class="form-control form-control-sm"
																		placeholder="input type" required>
																</div>
																<div class="col-md-3">
																	<div class="form-check">
																		<input id="RenameItem" v-model="item.status_rename" type="checkbox" value="1"
																			:checked="dataQuotation.include_tax === 1" class="form-check-input"
																			style="width: 15px; height: 15px;" @change="cekRenameItem">
																		<label for="RenameItem">Rename Item</label>
																	</div>
																</div>
															</div>
														</div>
														<div class="col-md-6 text-right">
															<a v-show="k || (!k && types.length > 1)" href="javascript:void(0)"
																class="btn btn-sm btn-danger" @click="remove(k)"> <i class="fas fa-trash"></i> Delete
															</a> &nbsp;
															<a v-show="k === types.length - 1" href="javascript:void(0)" class="btn btn-sm btn-success"
																@click="addLc">
																<i class="fas fa-plus"></i> New Type
															</a>
														</div>
													</div>
												</div>

												<div class="card-body">
													<div v-for="(isiDetail, d) in item.charge_item" :key="d" class="row">
														<div class="col-md-3">
															<div class="form-group">
																<label for="">Item</label>
																<!-- <multiselect v-model="isiDetail.item_id" placeholder="Choose Item" :options="listItemPpn" label="item_name">
																			</multiselect> -->
																<!-- <v-select v-model="isiDetail.item_id" :options="listItemPpn" label="item_name" :value="listItemPpn.id">
																</v-select> -->
																<select v-model="isiDetail.item_id" class="form-control form-control-sm"
																	data-live-search="true" placeholder="Choose an Item" required>
																	<option v-for="(isi, key) in listItemBank" :key="key" :value="isi.id">
																		{{ isi.item_name }}
																	</option>
																</select>

																<!-- <v-select v-model="isiDetail.item_id" :options="listItemPpn"  label="item_name" @input="changeItem">
																</v-select> -->
															</div>
														</div>
														<div class="col-md-2">
															<label for="">Currency</label>
															<select v-model="isiDetail.currency_id" class="form-control form-control-sm"
																data-live-search="true" placeholder="Choose an Item" required>
																<option v-for="(isi, key) in listCurrency" :key="key" :value="isi.id">
																	{{ isi.code }}
																</option>
															</select>
															<!-- <v-select v-model="curr" :options="listCurrency" label="code" @input="changeCurrCharge">
															</v-select> -->
														</div>
														<div class="col-md-2">
															<div class="form-group">
																<label for="">Price</label>
																<input v-model="isiDetail.price" type="text" class="form-control form-control-sm"
																	placeholder="Price" required />
															</div>
														</div>
														<!-- <div class="col-md-2">
															<label for="">Qty</label>
															<input v-model="isiDetail.qty" type="number" class="form-control form-control-sm" placeholder="Qty" required />
														</div> -->
														<div class="col-md-3">
															<div class="form-group">
																<label for="">Remark</label>
																<input v-model="isiDetail.remarks" type="text" class="form-control form-control-sm"
																	placeholder="Remarks" required />
															</div>
														</div>
														<div class="col-md-2 pt-1">
															<br>
															<a v-show="d || (!d && types[k].charge_item.length > 1)" href="javascript:void(0)"
																class="btn btn-sm btn-danger" @click="removeDetail(d, k)"> <i class="fas fa-trash"></i>
															</a> &nbsp;
															<a v-show="d === types[k].charge_item.length - 1" href="javascript:void(0)"
																class="btn btn-sm btn-success" @click="addDetail(k)"><i class="fas fa-plus"></i></a>
															&nbsp;
														</div>
													</div>
												</div>
											</div>
										</div>



									</div>
								</div>
							</div>
						</div>
					</div>

					<ul class="nav nav-tabs " style="border: 0;">
						<li class="active"><a href="#" class="btn btn-sm btn-head text-white">Remarks</a></li>
					</ul>
					<div class="card border">
						<div class="card-body">
							<div class="row mt-3">
								<div class="col-12">
									<div class="form-row">
										<!-- multiple remarks -->
										<div class="col-12" style="display: flex; align-items: center;">
											<label class="switch">
												<input type="checkbox" @click="toggleCheckbox" v-model="usingEditor">
												<div class="slider round"></div>
											</label>
											<span style="margin-left: 10px; font-size: 16px;">Activate Editor</span>
										</div>
										<div :class="'mt-3 col-md-' + (usingEditor ? '12' : '6')">
											<label for="">Remarks</label>
											<div v-for="(item, k) in remarks" :key="k" class="row mb-2">
												<div class="col-md-12" v-show="usingEditor">
													<quill-editor v-model="item.valueEditor" style="width: 100%;" />
												</div>
												<div class="col-md-9" v-show="!usingEditor">

													<input v-model="item.value" type="text" class="form-control form-control-sm"
														placeholder="enter remarks">
												</div>
												<div class="col-md-3" v-show="!usingEditor">
													<a v-show="k || (!k && remarks.length > 1)" href="javascript:void(0)"
														class="btn btn-danger btn-sm" @click="removeRemarks(k)"> <i class="fas fa-trash"></i>
													</a> &nbsp;
													<a v-show="k === remarks.length - 1" href="javascript:void(0)" class="btn btn-create btn-sm"
														@click="addRemarks"><i class="fas fa-plus"></i></a>
													&nbsp;
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="row">
						<div class="col-md-12 mb-3">
							<div class="float-right">
								<button type="button" class="btn btn-cancel btn-sm mr-1" @click="back">Cancel</button>
								<input type="submit" class="btn btn-create btn-sm" value="Save" />
							</div>
						</div>
					</div>

				</form>
			</div>
		</div>
	</Layout>
</template>
<script>
import appConfig from '@src/app.config'
import Layout from '@layouts/main'
// import PageHeader from '@components/page-header'
import Swal from 'sweetalert2'
import DatePicker from 'vue2-datepicker';
import moment from 'moment'
import axios from 'axios'
import { quillEditor } from "vue-quill-editor";
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

// import tinymce from 'vue-tinymce-editor'
// import Vuetable from 'vuetable-2/src/components/Vuetable'
import {
	mapState,
	mapActions
} from 'vuex'
// import datepicker from 'vue-date-picker'
// import DatePicker from 'vue2-datepicker'
// import {
//     ModelListSelect
// } from 'vue-search-select'
export default {
	name: 'Formreate',
	page: {
		title: 'Quotation',
		meta: [{
			name: 'description',
			content: appConfig.description,
		},],
	},
	components: {
		// ModelListSelect,
		// appConfig,
		Layout,
		// PageHeader,
		DatePicker,
		quillEditor
		// tinymce,
		// Vuetable
	},
	data() {
		return {
			momentFormat: {
				// this.dataPayload.year
				stringify: (date) => {
					return date ? moment(this.dataQuotation.date_input).format('YYYY-MM-DD') : ''
				},
			},
			title: 'Edit Quotation',
			// dataQuotation: {
			//     charges: []
			// },
			includeTax: 0,
			textarea: '',
			remarks: [{
				value: '',
				valueEditor: ''
			}],
			types: [{
				type: '',
				status_rename: '',
				charge_item: [{
					item: '',
					price: '',
					// qty: '',
					remarks: '',
					item_id: '',
					currency_id: '',
					curr: ''
				}]
			}],
			nameCustomer: null,
			pol: null,
			pod: null,
			idTerm: null,
			curr: null,
			ppn: null,
			itemPpn: null,
			value: null,
			listItemPpn: null,
			value1: null,
			isHidden: false,
			listType: [{
				id: 1,
				name: 'Local Charge'
			},
			{
				id: 2,
				name: 'Local Charge Destination'
			},
			{
				id: 3,
				name: 'Custom Clearance'
			},
			{
				id: 4,
				name: 'Fregiht/Kapal/Pelayaran'
			}
			],
			charges: [],
			fields: [{
				name: 'type',
				title: 'Type',
				sortField: 'type'
			}, {
				name: 'item.item_name',
				title: 'Item',
				sortField: 'item.item_name'
			}, {
				name: 'rate',
				title: 'Rate',
				sortField: 'rate'
			}, {
				name: 'curr.code',
				title: 'Currency',
				sortField: 'curr.code'
			}, {
				name: 'remarks',
				title: 'Remarks',
				sortField: 'remarks'
			}, {
				name: 'action',
				title: 'Action',
				titleClass: 'text-center',
				dataClass: 'text-center'
			}],
			sortOrder: [{
				field: 'type',
				direction: 'asc'
			}],
			newLocalCharge: [{
				type: '',
				item_id: '',
				price: '',
				qty: '',
				remark: ''
			}],
			listPpn: [{
				ppn: 0,
				name: 'Non PPN'
			},
			{
				ppn: 1,
				name: '1%'
			},
			{
				ppn: 10,
				name: '10%'
			}
			],
			remarksOptions: {
				'height': 100
			},
			remarksOperations: {
				'height': 250
			},
			type_Akun: '',
			items: [{
				text: 'Master',
				href: '/',
			},
			{
				text: 'Quotation',
				href: '/quotation',
			},
			{
				text: 'Form Edit',
				active: true,
			},
			],

			dataNomorPenerimaanInv: {
				kode_penerimaan_id: 1,
			},
			dataNomorPenerimaanKui: {
				kode_penerimaan_id: 2,
			},
			isLoading: false,
			defaultCurrency: null,
			usingEditor: false
		}
	},
	computed: {
		...mapState('customer', ['listCustomerQuo', 'dataCustomer']),
		...mapState('port', ['listPort', 'dataPort']),
		...mapState('currency', ['listCurrency', 'dataCurrency']),
		...mapState('item', ['listItem', 'listItemBank']),
		...mapState('term', ['listTerm', 'dataTerm']),
		...mapState('quotation', ['dataQuotation']),

	},
	watch: {
		data(newVal, oldVal) {
			this.$refs.vuetable.refresh();
		},
	},
	mounted() {
		this.getListCustomerQuo()
		this.getListPort()
		this.getListCurrency()
		this.getListTerm()
		this.getListItem()
		this.fetchData()
		this.getDefCur()
		this.getItemByCategoryBank({
			itemCategory: 1
		});

	},

	methods: {
		...mapActions('customer', ['getListCustomerQuo', 'getCustomer']),
		...mapActions('port', ['getListPort', 'getPort']),
		...mapActions('currency', ['getListCurrency', 'getCurrency']),
		...mapActions('item', ['getListItem', 'getItem', 'getItemByCategoryBank']),
		...mapActions('term', ['getListTerm', 'getTerm']),
		...mapActions('quotation', ['saveQuotation', 'getQuotation', 'updateQuotation']),

		getTgl() {
			this.dataQuotation.date_input = moment(this.dataQuotation.date_input).format('YYYY-MM-DD')
			console.log(this.dataQuotation.date_input)
		},

		getDefCur() {
			axios.get('/def/curr').then((response) => {
				// console.log(response.data,'def cuurency')
				this.dataQuotation.curr = response.data
				this.defaultCurrency = response.data.id
			}, () => {
				console.log("gagal")
			});
		},

		manualInput(value) {
			this.isHidden = !this.isHidden
			// if(this.isHidden === true){
			this.dataQuotation.customer_manual = ''
			console.log(this.dataQuotation.customer_name);
			// }
		},


		fetchData() {


			// get data quotation
			let id = this.$route.params.id
			// console.log('id', id)
			this.getQuotation(id)
				.then((res) => {
					// console.log('res', res.data)
					console.log('dataQuotation', this.dataQuotation)
					// // get data charges
					if (this.dataQuotation.charges) {
						this.types = this.dataQuotation.charges

					}
					// hide or not customer manual
					if (res.data.customer_manual) {
						this.isHidden = true
					}
					// console.log(this.types,'isi types quotation');
					// get includeTax

					if (this.dataQuotation.include_tax === 1) {
						this.includeTax = true
					}
					// get data remarks
					if (this.dataQuotation.remarks) {
						this.remarks = this.dataQuotation.remarks
					}

					this.remarks.forEach(dr => {
						dr.valueEditor = dr.value
					});

					if(this.dataQuotation.using_editor === 1) {
						this.usingEditor = true
					} else {
						this.usingEditor = false
					}

					this.$emit('setUsingEditorVal', this.usingEditor)

					// get item
					// let ppn = this.dataQuotation.ppn
					// // console.log('ppn', ppn)

					// this.getItemPpn(ppn).then((res) => {
					// 	this.listItemPpn = res
					// 	console.log(res, 'item response')
					// 	// console.log(this.ItemPpn)
					// }).catch((err) => {
					// 	console.log(err)
					// })
					console.log('charges', this.types)

					// get Item & Currency
					for (let i = 0; i < this.types.length; i++) {
						const element = this.types[i];

						for (let j = 0; j < element.charge_item.length; j++) {
							const isi = element.charge_item[j];
							console.log(isi.currency_id, 'ini isi currency ID')
							console.log(isi.item_id, 'ini isi Item ID')
							this.getItem(isi.item_id).then((res) => {
								this.listItem = res
								console.log(res, 'item response')
								// console.log(this.ItemPpn)
							}).catch((err) => {
								console.log(err)
							})

						}

					}

					// get customer
					let customerId = this.dataQuotation.customer_id
					console.log(customerId, 'id cust');
					this.getCustomer(customerId)
						.then((res) => {
							this.nameCustomer = this.dataCustomer.name
							this.dataQuotation.customer_id = this.dataCustomer.id
							console.log(this.nameCustomer, 'data dari api')
						})
						.catch((err) => {
							console.log(err)
							this.errorAlert()
						})

					// get pol
					let pol = this.dataQuotation.pol
					// console.log(this.dataQuotation.pol,'pol quoattio')
					this.getPort(pol)
						.then((res) => {
							this.pol = this.dataPort.name_port
							this.dataQuotation.pol = this.dataPort.id
							// console.log(this.dataPort, 'data dari api')
						})
						.catch((err) => {
							console.log(err)
							this.errorAlert()
						})

					// get pod
					let pod = this.dataQuotation.pod
					// console.log(this.dataQuotation.pod,'pod quoattio')
					this.getPort(pod)
						.then((res) => {
							this.pod = this.dataPort.name_port
							this.dataQuotation.pod = this.dataPort.id
							// console.log(this.dataPort, 'data dari api')
						})
						.catch((err) => {
							console.log(err)
							this.errorAlert()
						})

					// get term
					let idTerm = this.dataQuotation.term_id
					// console.log(this.dataQuotation.pod,'pod quoattio')
					this.getTerm(idTerm)
						.then((res) => {
							this.idTerm = this.dataTerm.name
							this.dataQuotation.term_id = this.dataTerm.id
							// console.log(this.dataPort, 'data dari api')
						})
						.catch((err) => {
							console.log(err)
							this.errorAlert()
						})

					// get currency
					let currencyId = this.dataQuotation.currency_id
					console.log(this.dataQuotation.currency_id, 'currency_id quoattio')
					this.getCurrency(currencyId)
						.then((res) => {
							this.curr = this.dataCurrency.code
							this.dataQuotation.currency_id = this.dataCurrency.id
							console.log('ami', this.curr)
							// console.log(this.dataPort, 'data dari api')
						})
						.catch((err) => {
							console.log(err)
							this.errorAlert()
						})

					// get ppn
					// let ppnId = this.dataQuotation.ppn
					// console.log(this.dataQuotation.ppn,'ppn quoattio')
					// let namePpn = '';
					// for (let i = 0; i < this.listPpn.length; i++) {
					// 	 namePpn = this.listPpn.ppn[i];
					// 	if(namePpn === ppnId ){
					// 		this.ppn = this.listPpn.name[i];
					// 		break;
					// 	}

					// }
					// console.log(this.ppn, 'ppn guys')


				})
				.catch((err) => {
					console.log(err)
				})
		},
		cekIncludeTax() {
			if (this.includeTax === true) {
				this.includeTax = 1
				// console.log(this.includeTax)
			}
		},
		cekRenameItem() {
			if (this.types.status_rename === true) {
				this.types.status_rename = 1
				// console.log(this.includeTax)
			}
		},
		changeType(value) {
			this.newLocalCharge.item = value
			this.newLocalCharge.item_id = value.id
			console.log('charge item', this.newLocalCharge.item_id)
		},
		changeCustomer(value) {
			this.dataQuotation.customer_name = value.name
			this.dataQuotation.customer_id = value.id
			console.log('customer_id', this.dataQuotation.customer_id)
		},

		changePod(value) {
			this.dataQuotation.podname = value
			this.dataQuotation.pod = value.id
			console.log('pod id', this.dataQuotation.pod)
		},
		changePol(value) {
			this.dataQuotation.polname = value
			this.dataQuotation.pol = value.id
			console.log('pol id', this.dataQuotation.pol)
		},
		changeTerm(value) {
			this.dataQuotation.term = value
			this.dataQuotation.term_id = value.id
			console.log('term id', this.dataQuotation.term_id)
		},
		changeItem(value) {
			this.newLocalCharge.item = value
			this.newLocalCharge.item_id = value.id
			console.log(' item', this.newLocalCharge.item_id)
		},
		changePpn(value) {
			this.dataQuotation.ppnShow = value
			this.dataQuotation.ppn = value.ppn

			let ppn = this.dataQuotation.ppn
			console.log('ppn', ppn)

			this.getItemPpn(ppn).then((res) => {
				this.listItemPpn = res
				// console.log(this.listItemPpn,'this item ppn')
				// console.log(this.ItemPpn)
			}).catch((err) => {
				console.log(err)
			})
		},
		changeCurrCharge(value) {
			this.dataQuotation.curr = value
			this.dataQuotation.currency_id = value.id
			this.dataQuotation.currency[0].default = value.currency_company.default
		},



		addRemarks() {
			if (!this.remarks) {
				return
			}
			this.remarks.push({
				value: '',
				valueEditor: ''
			});
		},
		removeRemarks(x) {
			this.remarks.splice(x, 1)
			this.saveRemarks()
		},
		saveRemarks() {
			const Toast = Swal.mixin({
				toast: true,
				position: 'top-end',
				showConfirmButton: false,
				timer: 2000,
				timerProgressBar: true,
				onOpen: (toast) => {
					toast.addEventListener('mouseenter', Swal.stopTimer)
					toast.addEventListener('mouseleave', Swal.resumeTimer)
				}
			})

			Toast.fire({
				icon: 'success',
				title: 'successfully'
			})
		},
		remove(x) {
			this.types.splice(x, 1)
			this.saveLocalCharges()
		},
		addLc() {
			if (!this.types) {
				return
			}
			this.types.push({
				type: '',
				charge_item: [{
					item: '',
					price: '',
					// qty: '',
					remarks: '',
					item_id: '',
					currency_id: '',
					curr: ''
				}]
			})
		},

		saveLocalCharges() {


			let parsed = JSON.stringify(this.types);
			const Toast = Swal.mixin({
				toast: true,
				position: 'top-end',
				showConfirmButton: false,
				timer: 2000,
				timerProgressBar: true,
				onOpen: (toast) => {
					toast.addEventListener('mouseenter', Swal.stopTimer)
					toast.addEventListener('mouseleave', Swal.resumeTimer)
				}
			})

			Toast.fire({
				icon: 'success',
				title: 'successfully'
			})
			localStorage.setItem('Local Charges', parsed)
		},
		addDetail(k) {
			if (!this.types[k].charge_item) {
				return
			}
			this.types[k].charge_item.push({
				item: '',
				price: '',
				// qty: '',
				remarks: '',
				item_id: '',
				currency_id: '',
				curr: ''
			});
		},
		removeDetail(d, k) {
			this.types[k].charge_item.splice(d, 1);
			this.saveDetail(k)
		},
		saveDetail(k) {
			const Toast = Swal.mixin({
				toast: true,
				position: 'top-end',
				showConfirmButton: false,
				timer: 2000,
				timerProgressBar: true,
				onOpen: (toast) => {
					toast.addEventListener('mouseenter', Swal.stopTimer)
					toast.addEventListener('mouseleave', Swal.resumeTimer)
				}
			})

			Toast.fire({
				icon: 'success',
				title: 'successfully'
			})
		},

		submitData() {
			if (this.dataQuotation.customer_manual) {
				this.dataQuotation.customer_name = this.dataQuotation.customer_manual
				this.dataQuotation.customer_id = 0
			}
			this.dataQuotation.include_tax = this.includeTax
			this.dataQuotation.charges = this.types
			console.log(this.dataQuotation.charges, 'isi charge')
			
			if(this.usingEditor) {
				this.remarks.forEach((dr, idx) => {
					this.remarks[idx]['value'] = dr.valueEditor
				});
			} 

			this.dataQuotation.remarks = this.remarks

			this.dataQuotation.using_editor = this.usingEditor ? 1 : 0
			if (this.dataQuotation.customer_manual) {
				this.dataQuotation.customer_name = this.dataQuotation.customer_manual
				this.dataQuotation.customer_id = 0
			}

			console.log('data quotation', this.dataQuotation)
			let headers = {
				'Content-Type': 'application/json',

			}


			this.updateQuotation({
				payload: this.dataQuotation,
				id: this.dataQuotation.id,
				headers: headers
			})
				.then((res) => {
					console.log(res)
					const Toast = Swal.mixin({
						toast: true,
						position: 'bottom-end',
						showConfirmButton: false,
						timer: 2000,
						timerProgressBar: true,
						onOpen: (toast) => {
							toast.addEventListener('mouseenter', Swal.stopTimer)
							toast.addEventListener('mouseleave', Swal.resumeTimer)
						}
					})

					Toast.fire({
						icon: 'success',
						title: 'successfully'
					})
					this.$router.push({
						name: 'Quotation List'
					})
				})
				.catch((err) => {
					console.log(err)
				})
		},
		back() {
			this.$router.push({
				name: 'Quotation List'
			})
		},
		toggleCheckbox() {
			this.usingEditor = !this.usingEditor
			this.$emit('setCheckboxVal', this.usingEditor)

			this.remarks = [{
				value: '',
				valueEditor: ''
			}]
		}
	},
}

</script>
